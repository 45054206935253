import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/men-dian-hang-ye/list',
        method: 'post',
        data
    })
}
//获取门店行业选择列表
export function getSelectList() {
    return request({
        url: `/men-dian-hang-ye/get-select-list`,
        method: 'get',
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/men-dian-hang-ye/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/men-dian-hang-ye/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/men-dian-hang-ye/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/men-dian-hang-ye/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/men-dian-hang-ye/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/men-dian-hang-ye/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/men-dian-hang-ye/query',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'men-dian-hang-ye-list',
    component: () => import('@/view/mdgl/mdhy/MenDianHangYeList'),
    name: 'MenDianHangYeList',
    meta: {title: '门店行业', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'men-dian-hang-ye-query',
    component: () => import('@/view/mdgl/mdhy/MenDianHangYeQuery'),
    name: 'MenDianHangYeQuery',
    meta: {title: '门店行业查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'men-dian-hang-ye-remind-list',
    component: () => import('@/view/mdgl/mdhy/MenDianHangYeRemindList'),
    name: 'MenDianHangYeRemindList',
    meta: {title: '门店行业提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"门店行业基础列表","methodUrl":"/men-dian-hang-ye/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"门店行业添加保存","methodUrl":"/men-dian-hang-ye/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"门店行业获取编辑数据","methodUrl":"/men-dian-hang-ye/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"门店行业获取详情数据","methodUrl":"/men-dian-hang-ye/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"门店行业编辑保存","methodUrl":"/men-dian-hang-ye/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"门店行业删除","methodUrl":"/men-dian-hang-ye/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"门店行业批量删除","methodUrl":"/men-dian-hang-ye/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"门店行业独立查询","methodUrl":"/men-dian-hang-ye/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
