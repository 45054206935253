<template>
    <el-tooltip class="item" effect="dark" :content="dm" placement="top" :disabled="!dm">
        <el-input size="small" v-model="text" v-if="edit" :placeholder="placeholder" :disabled="disabled" readonly/>
        <span v-else>{{ text }}</span>
    </el-tooltip>
</template>

<script>
//此组件用于回显各种根据单位代码生成的子孙代码 含库区、仓房、廒间、货位、货位卡等
const lens = {
    dwdm: {key: "dwdm", len: 18, start: 0, end: 18},
    kqdm: {key: "kqdm", len: 21, start: 18, end: 21},
    cfdm: {key: "cfdm", len: 25, start: 21, end: 25},
    ajdm: {key: "ajdm", len: 28, start: 25, end: 28},
    hwdm: {key: "hwdm", len: 30, start: 28, end: 30},
    hwkdm: {len: 36, yearStart: 30, yearEnd: 34, numberStart: 34, numberEnd: 36},
    crkdm: {len: 12, headStart: 0, headEnd: 2, timeStart: 2, timeEnd: 8, numberStart: 8, numberEnd: 12},
    crkjs: {len: 33, timeStart: 21, timeEnd: 29, numberStart: 29, numberEnd: 33},
    rkzldh: {len: 14, headStart: 0, headEnd: 4, timeStart: 4, timeEnd: 10, numberStart: 10, numberEnd: 14},
    sxwck: {len: 15, headStart: 0, headEnd: 5, timeStart: 5, timeEnd: 11, numberStart: 11, numberEnd: 15}
}

export default {
    name: "XDaiMa",
    computed: {
        text() {
            if (this.original || !this.dm) {
                return this.dm;
            }
            //处理仓房代码
            if (this.dm.length === lens.cfdm.len) {
                return this.getNumber(lens.cfdm.key);
            }
            //处理廒间代码
            if (this.dm.length === lens.ajdm.len) {
                let cfls = this.getNumber(lens.cfdm.key);
                let ajls = this.getNumber(lens.ajdm.key);
                return cfls.concat(this.connector).concat(ajls);
            }
            //处理货位代码
            if (this.dm.length === lens.hwdm.len) {
                let cfls = this.getNumber(lens.cfdm.key);
                let ajls = this.getNumber(lens.ajdm.key);
                let hwls = this.getNumber(lens.hwdm.key);
                return cfls.concat(this.connector)
                    .concat(ajls)
                    .concat(this.connector)
                    .concat(hwls);
            }
            // 处理货位卡代码
            if (this.dm.length === lens.hwkdm.len) {
                let cfls = this.getNumber(lens.cfdm.key);
                let ajls = this.getNumber(lens.ajdm.key);
                let hwls = this.getNumber(lens.hwdm.key);
                let hwkls = this.getHwkNumber();
                return cfls.concat(this.connector)
                    .concat(ajls)
                    .concat(this.connector)
                    .concat(hwls)
                    .concat(this.connector)
                    .concat(hwkls);
            }
            // 处理出入库代码
            if (this.dm.length === lens.crkdm.len) {
                return this.getCrkNumber();
            }
            // 处理入库整理代码
            if (this.dm.length === lens.rkzldh.len) {
                return this.getRkzlNumber();
            }
            // 处理出入库结算单代码
            if (this.dm.length === lens.crkjs.len) {
                return this.getCrkjsNumber();
            }
            // 处理筛下物代码
            if (this.dm.length === lens.sxwck.len) {
                return this.getSxwckNumber();
            }
        }
    },
    props: {
        dm: {
            type: String,
            default: ""
        },
        original: {
            type: Boolean,
            default: false
        },
        connector: {
            type: String,
            default: "-"
        },
        edit: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
    },
    methods: {
        getNumber(type) {
            return this.dm.substring(lens[type].start, lens[type].end);
        },
        getHwkNumber() {
            let year = this.dm.substring(lens.hwkdm.yearStart, lens.hwkdm.yearEnd);
            let number = this.dm.substring(lens.hwkdm.numberStart, lens.hwkdm.numberEnd);
            return year.concat(this.connector).concat(number);
        },
        getCrkNumber() {
            let head = this.dm.substring(lens.crkdm.headStart, lens.crkdm.headEnd);
            let time = this.dm.substring(lens.crkdm.timeStart, lens.crkdm.timeEnd);
            let number = this.dm.substring(lens.crkdm.numberStart, lens.crkdm.numberEnd);
            return head.concat(this.connector).concat(time).concat(this.connector).concat(number);
        },
        getRkzlNumber() {
            let head = this.dm.substring(lens.rkzldh.headStart, lens.rkzldh.headEnd);
            let time = this.dm.substring(lens.rkzldh.timeStart, lens.rkzldh.timeEnd);
            let number = this.dm.substring(lens.rkzldh.numberStart, lens.rkzldh.numberEnd);
            return head.concat(this.connector).concat(time).concat(this.connector).concat(number);
        },
        getCrkjsNumber() {
            let time = this.dm.substring(lens.crkjs.timeStart, lens.crkjs.timeEnd);
            let number = this.dm.substring(lens.crkjs.numberStart, lens.crkjs.numberEnd);
            return time.concat(this.connector).concat(number);
        },
        getSxwckNumber() {
            let head = this.dm.substring(lens.sxwck.headStart, lens.sxwck.headEnd);
            let time = this.dm.substring(lens.sxwck.timeStart, lens.sxwck.timeEnd);
            let number = this.dm.substring(lens.sxwck.numberStart, lens.sxwck.numberEnd);
            return head.concat(this.connector).concat(time).concat(this.connector).concat(number);
        }
    }
}
</script>

<style scoped>

</style>
