import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/cai-gou-dan-xiang-qing/list',
        method: 'post',
        data
    })
}

//基础列表
export function selector(data) {
    return request({
        url: '/cai-gou-dan-xiang-qing/selector',
        method: 'post',
        data
    })
}

// 获取采购单详情表信息列表  czy
export function getCaiGouDanXiangQingList(spId) {
    return request({
        url: `/cai-gou-dan-xiang-qing/get-cai-gou-dan-xq-list/${spId}`,
        method: 'post',
    })
}

/*

//添加保存
export function add(data) {
    return request({
        url: '/cai-gou-dan-xiang-qing/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/cai-gou-dan-xiang-qing/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/cai-gou-dan-xiang-qing/get-detail/${id}`,
        method: 'get',
    })
}


//编辑保存
export function update(data) {
    return request({
        url: '/cai-gou-dan-xiang-qing/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/cai-gou-dan-xiang-qing/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/cai-gou-dan-xiang-qing/delete-batch',
        method: 'post',
        data
    })
}
*/
//独立查询
export function query(data) {
    return request({
        url: '/cai-gou-dan-xiang-qing/query',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'cai-gou-dan-xiang-qing-list',
    component: () => import('@/view/cggl/cgspmx/CaiGouDanXiangQingList'),
    name: 'CaiGouDanXiangQingList',
    meta: {title: '采购单详情', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'cai-gou-dan-xiang-qing-query',
    component: () => import('@/view/cggl/cgspmx/CaiGouDanXiangQingQuery'),
    name: 'CaiGouDanXiangQingQuery',
    meta: {title: '采购单详情查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'cai-gou-dan-xiang-qing-remind-list',
    component: () => import('@/view/cggl/cgspmx/CaiGouDanXiangQingRemindList'),
    name: 'CaiGouDanXiangQingRemindList',
    meta: {title: '采购单详情提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"采购单详情基础列表","methodUrl":"/cai-gou-dan-xiang-qing/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"采购单详情添加保存","methodUrl":"/cai-gou-dan-xiang-qing/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"采购单详情获取编辑数据","methodUrl":"/cai-gou-dan-xiang-qing/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"采购单详情获取详情数据","methodUrl":"/cai-gou-dan-xiang-qing/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"采购单详情编辑保存","methodUrl":"/cai-gou-dan-xiang-qing/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"采购单详情删除","methodUrl":"/cai-gou-dan-xiang-qing/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"采购单详情批量删除","methodUrl":"/cai-gou-dan-xiang-qing/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"采购单详情独立查询","methodUrl":"/cai-gou-dan-xiang-qing/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
