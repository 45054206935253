<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="入库仓库" >
                <el-input v-model="search.ruKuCangKu" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border @selection-change="handleSelectionChange">
            <el-table-column v-if="selection" type="selection" width="40"/>
            <el-table-column v-if="!selection" width="100" align="center" label="操作">
                <el-button slot-scope="{row}" type="success" size="mini" round @click="handleConfirm(row)">确认</el-button>
            </el-table-column>
            <el-table-column width="180" prop="ruKuLX" label="入库类型" />
            <el-table-column width="180" prop="ruKuCangKu" label="入库仓库" />
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
    </div>
</template>
<script>
    import {selector} from "@/service/hlgl/RuKuXX";
    import XTableSelector from "@/components/x/XTableSelector";

    export default {
        name: "RuKuXXSelector",
        mixins: [XTableSelector],
        data() {
            this.refreshService = selector;
            return {
                search: {
                    ruKuCangKu: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>