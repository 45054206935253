<template>
    <div class="x-page-container" ref="resize">
<!--        <div class="x-search-item">-->
<!--            <el-button v-if="selection" type="primary" size="mini" @click="handleConfirms">确认选择</el-button>-->
<!--        </div>-->
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="仓库编号">
                <el-input v-model="search.cangKuBH" size="small"/>
            </x-search-item>
            <x-search-item label="仓库名称" v-if="openHs">
                <el-input v-model="search.cangKuMC" size="small"/>
            </x-search-item>
            <x-search-item label="供货商"v-show="false">
                <el-input v-model="search.gongHuoShang" size="small"/>
            </x-search-item>
            <x-search-item label="供货商ID" v-show="false">
                <el-input v-model="search.gongHuoShangID" size="small"/>
            </x-search-item>
            <x-search-item label="商品货号" v-if="openHs">
                <el-input v-model="search.shangPinHuoHao" size="small"/>
            </x-search-item>
            <x-search-item label="商品名称" v-if="openHs">
                <el-input v-model="search.shangPinMC" size="small"/>
            </x-search-item>
            <x-search-item label="规格货号" v-if="openHs">
                <el-input v-model="search.guiGeHuoHao" size="small"/>
            </x-search-item>
            <x-search-item label="规格条码" v-if="openHs">
                <el-input v-model="search.guiGeTiaoMa"  size="small" />
            </x-search-item>
            <x-search-item label="商品单位" v-if="openHs">
                <el-input v-model="search.shangPinDW" size="small" />
            </x-search-item>
<!--            <x-search-item label="生产日期" v-if="openHs">-->
<!--                <el-input v-model="search.shengChanRQ" size="small" />-->
<!--            </x-search-item>-->
<!--            <x-search-item label="保质期（天）" v-if="openHs">-->
<!--                <el-input v-model="search.baoZhiQiTian" size="small" />-->
<!--            </x-search-item>-->
<!--            <x-search-item label="库存状态" v-if="openHs">-->
<!--                <el-input v-model="search.kuCunZT" size="small" />-->
<!--            </x-search-item>-->
            <x-search-item label="上下架状态" v-if="openHs">
                <el-input v-model="search.shangXiaJiaZT" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <!--<el-button type="primary" size="mini" @click="handleClear">清空</el-button>-->
                <el-button type="primary" size="mini" @click="openHs = !openHs">{{ openHs?'收起':'高级查询' }}</el-button>
                <el-button v-if="selection" type="primary" size="mini" @click="handleConfirms">确认选择</el-button>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column v-if="selection" type="selection" width="40"/>
            <el-table-column v-if="!selection" width="100" align="center" label="操作">
                <el-button slot-scope="{row}" type="success" size="mini" round @click="handleConfirm(row)">确认</el-button>
            </el-table-column>
            <el-table-column width="180" prop="shangPinMC" label="商品名称">
                <!--                <XJiHuaDH slot-scope="{row}" :dm="row.shangPinMC"/>-->
            </el-table-column>
<!--            <el-table-column width="150" prop="id" label="id">-->
<!--            <el-table-column width="170" prop="cangKuBH" label="仓库编号">
&lt;!&ndash;                <XJiHuaDH slot-scope="{row}" :dm="row.cangKuBH"/>&ndash;&gt;
            </el-table-column>-->
            <el-table-column width="120" prop="cangKuMC" label="仓库名称">
<!--                <XJiHuaDH slot-scope="{row}" :dm="row.cangKuMC"/>-->
            </el-table-column>
            <el-table-column width="120" prop="gongHuoShang" label="供货商">
<!--                <XJiHuaDH slot-scope="{row}" :dm="row.cangKuMC"/>-->
            </el-table-column>
<!--            <el-table-column width="120" prop="gongHuoShangID" label="供货商ID">
&lt;!&ndash;                <XJiHuaDH slot-scope="{row}" :dm="row.cangKuMC"/>&ndash;&gt;
            </el-table-column>-->
<!--            <el-table-column width="160" prop="shangPinId" label="商品id">
            </el-table-column>-->
            <el-table-column width="160" prop="shangPinGuiGe" label="规格">
<!--                <XJiHuaDH slot-scope="{row}" :dm="row.shangPinHuoHao"/>-->
            </el-table-column>
            <el-table-column width="160" prop="shangPinHuoHao" label="货号">
<!--                <XJiHuaDH slot-scope="{row}" :dm="row.shangPinHuoHao"/>-->
            </el-table-column>
            <el-table-column width="160" prop="shangPinTiaoMa" label="条码">
<!--                <XJiHuaDH slot-scope="{row}" :dm="row.shangPinHuoHao"/>-->
            </el-table-column>
            <el-table-column width="160" prop="shangPinDW" label="单位">
<!--                <XJiHuaDH slot-scope="{row}" :dm="row.shangPinDW"/>-->
            </el-table-column>
            <el-table-column width="180" prop="shengChanRQ" label="生产日期">
<!--                <XJiHuaDH slot-scope="{row}" :dm="row.shengChanRQ"/>-->
            </el-table-column>
            <el-table-column width="100" prop="baoZhiQiTian" label="保质期（天）">
<!--                <XJiHuaDH slot-scope="{row}" :dm="row.baoZhiQiTian"/>-->
            </el-table-column>
            <el-table-column width="180" prop="shiJiKuCun" label="供应库存"/>
            <el-table-column width="100" prop="chengBenJia" label="成本价"/>
            <el-table-column width="120" prop="kuCunJinE" label="库存金额"/>
<!--            <el-table-column width="100" prop="kuCunZT" label="库存状态">-->
<!--&lt;!&ndash;                <XJiHuaDH slot-scope="{row}" :dm="row.kuCunZT"/>&ndash;&gt;-->
<!--            </el-table-column>-->
<!--            <el-table-column width="100" prop="shangXiaJiaZT" label="上下架状态"/>-->
<!--&lt;!&ndash;                <XJiHuaDH slot-scope="{row}" :dm="row.shangXiaJiaZT"/>&ndash;&gt;-->
<!--&lt;!&ndash;                <x-dict-show slot-scope="{row}" :code="row.shangXiaJiaZT" dictType="T_USE" />&ndash;&gt;-->
<!--            <el-table-column width="120" prop="beiZhu" label="备注"/>-->
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       :current-page.sync="searchNoClear.current"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
    </div>
</template>
<script>
    import {selector} from "@/service/kcgl/KuCunZhuangKuang";
    import XTableSelector from "@/components/x/XTableSelector";
    import XJiHuaDH from "@/components/x/XJiHuaDH";

    export default {
        name: "KuCunZhuangKuangSelector",
        mixins: [XTableSelector],
        components: {XJiHuaDH},
        props: {
            param: {
                cangKuMC: "",
                gongHuoShang: "",
            }
        },
        data() {
            this.refreshService = selector;
            return {
                search: {
                    // id: '',
                    cangKuBH: '',
                    cangKuMC: this.param.cangKuMC,
                    shangPinHuoHao: '',
                    shangPinId: '',
                    gongHuoShang: this.param.gongHuoShang,
                    gongHuoShangID: '',
                    shangPinMC: '',
                    guiGeHuoHao: '',
                    guiGeTiaoMa: '',
                    shangPinDW: '',
                    // shengChanRQ: '',
                    // baoZhiQiTian: '',
                    kuCunZT: '',
                    shangXiaJiaZT: '',

                }
            }
        }
    }
</script>

<style scoped>
</style>