<template>
    <el-select v-model="model" v-bind="$attrs" v-on="$listeners" :style="$vnode.data.style" :class="$vnode.data.class"
               :size="size" filterable :filter-method="filterMethod" style="width:100%" clearable>
        <el-option v-for="d in options" :key="d[valueKey]" :label="d[labelKey]" :value="d[valueKey]"></el-option>
    </el-select>
</template>

<script>
    import {getSelectList} from "@/service/mdgl/MenDianHangYe";
    import XSelect from "@/components/x/XSelect";

    export default {
        name: "MenDianHangYeSelector",
        mixins: [XSelect],
        beforeCreate() {
            this.selector = getSelectList;
        },
        data(){
            return {
                valueKey: "id",
                labelKey: "xingYeMC",
            }
        }
    }


</script>
