import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/hui-yuan-dj/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/hui-yuan-dj/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/hui-yuan-dj/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/hui-yuan-dj/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/hui-yuan-dj/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/hui-yuan-dj/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/hui-yuan-dj/delete-batch',
        method: 'post',
        data
    })
}

//会员等级list
export function hydjlist() {
    return request({
        url: '/hui-yuan-dj/all',
        method: 'post',
    })
}

/*
列表路由
{
    path: 'hui-yuan-dj-list',
    component: () => import('@/view/hygl/hydj/HuiYuanDJList'),
    name: 'HuiYuanDJList',
    meta: {title: '会员等级', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"会员等级基础列表","methodUrl":"/hui-yuan-dj/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"会员等级添加保存","methodUrl":"/hui-yuan-dj/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"会员等级获取编辑数据","methodUrl":"/hui-yuan-dj/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"会员等级获取详情数据","methodUrl":"/hui-yuan-dj/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"会员等级编辑保存","methodUrl":"/hui-yuan-dj/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"会员等级删除","methodUrl":"/hui-yuan-dj/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"会员等级批量删除","methodUrl":"/hui-yuan-dj/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
