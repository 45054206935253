import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/ru-ku-xx/list',
        method: 'post',
        data
    })
}

//调拨入库update czy
export function diaoBoRuKuUpdate(data) {
    return request({
        url: '/ru-ku-xx/diao-bo-ru-ku-update',
        method: 'post',
        data
    })
}
//  拒收 czy
export function jvShou(id) {
    return request({
        url: `/ru-ku-xx/jv-shou/${id}`,
        method: 'post',
    })
}

//调拨出库确认 czy
export function dbUpdateRuKuDan(id) {
    return request({
        url: `/chu-ku-xx/db-update-rkd/${id}`,
        method: 'post',
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/ru-ku-xx/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/ru-ku-xx/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/ru-ku-xx/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/ru-ku-xx/update',
        method: 'post',
        data
    })
}



//删除
export function del(id) {
    return request({
        url: `/ru-ku-xx/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/ru-ku-xx/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/ru-ku-xx/query',
        method: 'post',
        data
    })
}

//选择元列表
export function selector(data) {
    return request({
        url: '/ru-ku-xx/selector',
        method: 'post',
        data
    })
}

//选择元列表
export function rkmxSelector(data) {
    return request({
        url: '/ru-ku-xx/rkmxSelector',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'ru-ku-xx-list',
    component: () => import('@/view/hlgl/rkd/RuKuXXList'),
    name: 'RuKuXXList',
    meta: {title: '入库信息', isTag: true, isMenu: true, searchable: true}
}

选择元
{key:'RuKuXXSelector', value:'入库信息'}

菜单识别:基础相关-list为入口
[
{"name":"入库信息基础列表","methodUrl":"/ru-ku-xx/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"入库信息添加保存","methodUrl":"/ru-ku-xx/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"入库信息获取编辑数据","methodUrl":"/ru-ku-xx/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"入库信息获取详情数据","methodUrl":"/ru-ku-xx/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"入库信息编辑保存","methodUrl":"/ru-ku-xx/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"入库信息删除","methodUrl":"/ru-ku-xx/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"入库信息选择元列表","methodUrl":"/ru-ku-xx/selector","operateType":"search","sort":18,"functionClassification":"0","_key":"18"},
{"name":"入库信息批量删除","methodUrl":"/ru-ku-xx/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
