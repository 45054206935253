<template>
    <el-select v-model="model" v-bind="$attrs" v-on="$listeners" :style="$vnode.data.style" :class="$vnode.data.class"
               :size="size" style="width:100%" placeholder="请选择" filterable clearable :filter-method="filterMethod"
               :multiple="multiple" @blur="refresh">
        <el-option v-for="item in options" :key="item[key]" :label="isShowCode ? item[key] : item[name]" :value="item[key]">
            <span class="selector-left">{{ item[name] }}</span>
            <span class="selector-right"><x-dai-ma v-if="xDaiMaList.includes(key)" :dm="item[key]"/><span v-else>{{ item[key] }}</span></span>
        </el-option>
    </el-select>
</template>
<script>
    import {isFunction,debounce} from 'min-dash';
    import {lens} from '@/util/constant';
    import XDaiMa from "@/components/x/XDaiMa";

    export default {
        name: "XSelect",
        components: {XDaiMa},
        data() {
            return {
                options: [],
                opts: [],
                xDaiMaList: lens&&Object.keys(lens).filter(item=>!(['crkdm','crkjs','rkzldh','sxwck'].includes(item)))||[]
            }
        },
        model: {
            prop: "selected",
            event: "selected"
        },
        computed: {
            model: {
                get() {
                    if (this.multiple) {
                        if (!this.selected) return [];
                        return this.selected.split(",");
                    }
                    return this.selected;
                },
                set(value) {//如果单选,value为单个编码,如果多选,value为编码集合
                    this.multiple?this.handleMutiple(value):this.handleSingle(value)
                }
            }
        },
        props: {
            size: {
                type: String,
                default: "small"
            },
            selected: {
                type: [String, Array],
                required: true
            },
            multiple: {
                type: Boolean,
                default: false
            },
            param: {
                type: Object,
            },
            isShowCode:{
                type: Boolean,
                default: false
            }
        },
        watch: {
            "selected": {
                handler: (function (value, o) {
                    if (value) {
                        if (!this.multiple) this.filterMethod(value)
                    } else {
                        this.defaultOpt()
                    }
                }),
            },
            "param": {
                handler: debounce(function (v, o) {
                    v && this.refresh();
                }, 200),
            },
        },
        mounted() {
            this.refresh();
        },
        methods: {
            filterMethod(val) {
                this.options = this.opts && this.opts.filter(o => o.search.indexOf(val) > -1) || [];
            },
            refresh() {
                let service = this.param ? this.selector(this.param) : this.selector();
                isFunction(this.selector) && service.then(res => {
                    this.opts = res.data || [];
                    //编辑页回显
                    if (this.selected) {
                        if (this.multiple) {//多选返回
                            let arr = this.selected.split(",")
                            this.options = []
                            arr.forEach(sel => {
                                this.options.push(this.opts && this.opts.find(o => o.search.indexOf(sel) > -1))
                            })
                        } else {
                            this.filterMethod(this.selected)
                        }
                    } else {
                        this.defaultOpt()
                    }
                })
            },
            //默认下拉框值
            defaultOpt() {
                if (this.opts && this.opts.length > 10) {
                    this.options = this.opts.slice(0, 10);
                } else {
                    this.options = this.opts;
                }
            },
            //处理多选
            handleMutiple(value){
                this.$emit("selected", value.join(","))
                // 向父组件 传递 变更的整个对象
                let objs = [];//多选对象集合
                let names = [];//多选对象名称集合
                value.forEach(sel => {
                    let obj = this.opts && this.opts.find(o => o.search && o.search.indexOf(sel) > -1)
                    objs.push(obj)
                    names.push(obj[this.name])
                })
                this.$emit('update:name', names.join(","));
                this.$emit('changeOther', objs);
            },
            //处理单选
            handleSingle(value){
                // 设置值
                this.$emit('selected', value);
                // 向父组件 传递 变更的整个对象
                let obj = this.opts.find(o => o[this.key] === value);
                if (obj) {
                    this.$emit('update:name', obj[this.name]);
                    this.$emit('changeOther', obj);
                }
            },
        }
    }
</script>
