import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/diao-bo-shen-qing/list',
        method: 'post',
        data
    })
}
//调拨申请详情列表
export function getDiaoBoShenQingXQList(id) {
    return request({
        url: '/diao-bo-shen-qing/get-diao-bo-shen-qing-xq-list',
        method: 'get',
        data
    })
}

//通过调拨单的商品ID查询库存表数据
export function getKuCunByDBShangPinID(data) {
    return request({
        url: '/ku-cun-zhuang-kuang/get-kczk-list-by-spids',
        method: 'post',
        data
    })
}


//添加保存
export function add(data) {
    return request({
        url: '/diao-bo-shen-qing/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/diao-bo-shen-qing/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/diao-bo-shen-qing/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/diao-bo-shen-qing/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/diao-bo-shen-qing/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/diao-bo-shen-qing/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/diao-bo-shen-qing/query',
        method: 'post',
        data
    })
}

//审核列表
export function auditList(data) {
    return request({
        url: '/diao-bo-shen-qing/audit-list',
        method: 'post',
        data
    })
}

//获取审核内容
export function getAudit(id) {
    return request({
        url: `/diao-bo-shen-qing/get-audit/${id}`,
        method: 'get',
    })
}

//审核
export function audit(data) {
    return request({
        url: '/diao-bo-shen-qing/audit',
        method: 'post',
        data
    })
}

//选择元列表
export function selector(data) {
    return request({
        url: '/diao-bo-shen-qing/selector',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'diao-bo-shen-qing-list',
    component: () => import('@/view/hlgl/sqdbgl/DiaoBoShenQingList'),
    name: 'DiaoBoShenQingList',
    meta: {title: '调拨申请', isTag: true, isMenu: true, searchable: true}
}

审核列表路由
{
    path: 'diao-bo-shen-qing-audit-list',
    component: () => import('@/view/hlgl/sqdbgl/DiaoBoShenQingAuditList'),
    name: 'DiaoBoShenQingAuditList',
    meta: {title: '调拨申请审核', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'diao-bo-shen-qing-query',
    component: () => import('@/view/hlgl/sqdbgl/DiaoBoShenQingQuery'),
    name: 'DiaoBoShenQingQuery',
    meta: {title: '调拨申请查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'diao-bo-shen-qing-remind-list',
    component: () => import('@/view/hlgl/sqdbgl/DiaoBoShenQingRemindList'),
    name: 'DiaoBoShenQingRemindList',
    meta: {title: '调拨申请提醒', isTag: true, isMenu: true, searchable: true}
}

选择元
{key:'DiaoBoShenQingSelector', value:'调拨申请'}

菜单识别:基础相关-list为入口
[
{"name":"调拨申请基础列表","methodUrl":"/diao-bo-shen-qing/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"调拨申请添加保存","methodUrl":"/diao-bo-shen-qing/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"调拨申请获取编辑数据","methodUrl":"/diao-bo-shen-qing/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"调拨申请获取详情数据","methodUrl":"/diao-bo-shen-qing/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"调拨申请编辑保存","methodUrl":"/diao-bo-shen-qing/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"调拨申请删除","methodUrl":"/diao-bo-shen-qing/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"调拨申请选择元列表","methodUrl":"/diao-bo-shen-qing/selector","operateType":"search","sort":18,"functionClassification":"0","_key":"18"},
{"name":"调拨申请批量删除","methodUrl":"/diao-bo-shen-qing/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"调拨申请独立查询","methodUrl":"/diao-bo-shen-qing/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
菜单识别:审核相关-audit-list为入口
[
{"name":"调拨申请审核列表","methodUrl":"/diao-bo-shen-qing/audit-list","operateType":"search","sort":12,"functionClassification":"0","_key":"12"},
{"name":"调拨申请获取审核内容","methodUrl":"/diao-bo-shen-qing/get-audit/{id}","operateType":"search","sort":13,"functionClassification":"0","_key":"13"},
{"name":"调拨申请审核","methodUrl":"/diao-bo-shen-qing/audit","operateType":"edit","sort":14,"functionClassification":"0","_key":"14"}
]
*/
