import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/ku-cun-zhuang-kuang/list',
        method: 'post',
        data
    })
}
//临期列表
export function linQilist(data) {
    return request({
        url: '/ku-cun-zhuang-kuang/lin-qi-list',
        method: 'post',
        data
    })
}

//基础列表
export function getKuCunZKList(data) {
    return request({
        url: '/ku-cun-zhuang-kuang/get-ku-cun-zk-list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/ku-cun-zhuang-kuang/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/ku-cun-zhuang-kuang/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/ku-cun-zhuang-kuang/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/ku-cun-zhuang-kuang/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/ku-cun-zhuang-kuang/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/ku-cun-zhuang-kuang/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/ku-cun-zhuang-kuang/query',
        method: 'post',
        data
    })
}

//根据仓库名称获取数据
export function getListByCangKuMC(cangKuMC) {
    return request({
        url: `/ku-cun-zhuang-kuang/get-list-by-cang-ku-m-c/${cangKuMC}`,
        method: 'get',
    })
}

//选择元列表
export function selector(data) {
    return request({
        url: '/ku-cun-zhuang-kuang/selector',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'ku-cun-zhuang-kuang-list',
    component: () => import('@/view/kcgl/kczk/KuCunZhuangKuangList'),
    name: 'KuCunZhuangKuangList',
    meta: {title: '库存状况', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'ku-cun-zhuang-kuang-query',
    component: () => import('@/view/kcgl/kczk/KuCunZhuangKuangQuery'),
    name: 'KuCunZhuangKuangQuery',
    meta: {title: '库存状况查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'ku-cun-zhuang-kuang-remind-list',
    component: () => import('@/view/kcgl/kczk/KuCunZhuangKuangRemindList'),
    name: 'KuCunZhuangKuangRemindList',
    meta: {title: '库存状况提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"库存状况基础列表","methodUrl":"/ku-cun-zhuang-kuang/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"库存状况添加保存","methodUrl":"/ku-cun-zhuang-kuang/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"库存状况获取编辑数据","methodUrl":"/ku-cun-zhuang-kuang/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"库存状况获取详情数据","methodUrl":"/ku-cun-zhuang-kuang/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"库存状况编辑保存","methodUrl":"/ku-cun-zhuang-kuang/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"库存状况删除","methodUrl":"/ku-cun-zhuang-kuang/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"库存状况批量删除","methodUrl":"/ku-cun-zhuang-kuang/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"库存状况独立查询","methodUrl":"/ku-cun-zhuang-kuang/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
