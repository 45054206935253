<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="采购单ID"  v-show="openHs">
                <el-input v-model="search.caiGouDanID" size="small" />
            </x-search-item>
            <x-search-item label="供货商"  >
                <el-input v-model="search.gongHuoShang" v-show="false" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <!--<el-button type="primary" size="mini" @click="handleClear">清空</el-button>-->
                <el-button type="primary" size="mini" @click="openHs = !openHs">{{ openHs?'收起':'高级查询' }}</el-button>
                <el-button v-if="selection" type="primary" size="mini" @click="handleConfirms">确认选择</el-button>
            </div>


        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border @selection-change="handleSelectionChange">
            <el-table-column v-if="selection" type="selection" width="40"/>
            <el-table-column v-if="!selection" width="100" align="center" label="操作">
                <el-button slot-scope="{row}" type="success" size="mini" round @click="handleConfirm(row)">确认</el-button>
            </el-table-column>
            <el-table-column width="180" prop="id" label="采购单详情ID" />
            <el-table-column width="180" prop="caiGouDanID" label="采购单ID" />
            <el-table-column width="180" prop="shangPinMC" label="商品名称" />
            <el-table-column width="180" prop="gongHuoShang" label="供货商" />
            <el-table-column width="180" prop="guiGe" label="规格" />
            <el-table-column width="180" prop="huoHao" label="货号" />
            <el-table-column width="180" prop="tiaoMa" label="条码" />
            <el-table-column width="180" prop="danWei" label="单位" />
            <el-table-column width="180" prop="yiRuKuSL" label="已入库数量" />
            <el-table-column width="180" prop="jinHuoJia" label="进货价" />
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
    </div>
</template>
<script>
    import {selector} from "@/service/cggl/CaiGouDanXiangQing";
    import XTableSelector from "@/components/x/XTableSelector";

    export default {
        name: "CaiGouDanXiangQingSelector",
        mixins: [XTableSelector],
        props: {
            param: {
                caiGouDanID: "",
                gongHuoShang: "",
                caiGouCangKu: "",
            }
        },
        data() {
            this.refreshService = selector;
            return {
                search: {
                    caiGouDanID: this.param.caiGouDanID,
                    gongHuoShang: this.param.gongHuoShang,
                    caiGouCangKu: this.param.caiGouCangKu,
                }
            }
        }
    }
</script>

<style scoped>
</style>