<script>
import {hydjlist} from "@/service/hygl/HuiYuanDJ";
import XSelect from "@/components/x/XSelect";

export default {
    name: "HuiYuanDJSelector",
    mixins: [XSelect],
    beforeCreate() {
        this.key = 'huiYuanMC';
        this.name = 'huiYuanDJ';
        this.selector = hydjlist;
    }
}
</script>