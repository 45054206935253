import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/cang-ku/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/cang-ku/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/cang-ku/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/cang-ku/get-detail/${id}`,
        method: 'get',
    })
}

//获取供给仓库数据
export function getGongJiCangKu() {
    return request({
        url: '/cang-ku/get-gj-cang-ku',
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/cang-ku/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/cang-ku/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/cang-ku/delete-batch',
        method: 'post',
        data
    })
}

//启用/禁用
export function use(data) {
    return request({
        url: '/cang-ku/use',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/cang-ku/query',
        method: 'post',
        data
    })
}

//选择元列表
export function selector(data) {
    return request({
        url: '/cang-ku/selector',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'cang-ku-list',
    component: () => import('@/view/cggl/ckgl/CangKuList'),
    name: 'CangKuList',
    meta: {title: '仓库', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'cang-ku-query',
    component: () => import('@/view/cggl/ckgl/CangKuQuery'),
    name: 'CangKuQuery',
    meta: {title: '仓库查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'cang-ku-remind-list',
    component: () => import('@/view/cggl/ckgl/CangKuRemindList'),
    name: 'CangKuRemindList',
    meta: {title: '仓库提醒', isTag: true, isMenu: true, searchable: true}
}

选择元
{key:'CangKuSelector', value:'仓库'}

菜单识别:基础相关-list为入口
[
{"name":"仓库基础列表","methodUrl":"/cang-ku/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"仓库添加保存","methodUrl":"/cang-ku/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"仓库获取编辑数据","methodUrl":"/cang-ku/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"仓库获取详情数据","methodUrl":"/cang-ku/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"仓库编辑保存","methodUrl":"/cang-ku/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"仓库删除","methodUrl":"/cang-ku/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"仓库启用/禁用","methodUrl":"/cang-ku/use","operateType":"edit","sort":9,"functionClassification":"0","_key":"9"},
{"name":"仓库选择元列表","methodUrl":"/cang-ku/selector","operateType":"search","sort":18,"functionClassification":"0","_key":"18"},
{"name":"仓库批量删除","methodUrl":"/cang-ku/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"仓库独立查询","methodUrl":"/cang-ku/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
